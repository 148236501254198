import React, { Component } from "react";
import PropTypes from "prop-types";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import {
    Grid,
    Typography,
    Card,
    CardMedia,
    CardContent,
} from '@mui/material';
import TruncateMarkup from "react-truncate-markup";
import { withStyles } from "@mui/styles";
import NewsCard from './NewsCard';
import DialogBox from './DialogBox';
import {
    constants,
    PADDING_MEDIUM,
    PADDING_SMALL,
    paddingContentContainer,
} from "../constants";

const IMAGE_MAX_HEIGHT = 150;
const zoom = window.zoomNews ? window.zoomNews : 1;

const imageMaxHeightNews = parseInt(window.imageMaxHeightNews, 10)
    ? parseInt(window.imageMaxHeightNews, 10)
    : IMAGE_MAX_HEIGHT;

const hideImage = imageMaxHeightNews === -1;

class NewsCardCarousel extends Component {
    state = {
        open: false,
    };

    getCityOrAggloName = (news) => {
        if (news) {
            return news.agglo ? news.agglo_name : news.city_name;
        }
        return "";
    };

    generateNewsCard = news => {
        return (
            <div>
                <NewsCard news={news} key={news.id} popupCarousel />
            </div>
        );
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    onHandleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { open } = this.state
        const { classes, news } = this.props;
        if (!news) return null;

        return (
            <div>
                <Card className={classes.card} onClick={this.handleClickOpen}>
                    {!hideImage ? (
                        <LazyLoadComponent
                            placeholder={<div className={classes.media} />}
                        >
                            <CardMedia
                                component="img"
                                alt={news.image ? news.title : null}
                                className={classes.media}
                                image={news.image}
                                title={news.title}
                            />
                        </LazyLoadComponent>
                    ) : null}
                    <CardContent title={news.title} style={{ paddingBottom: 16 }} >
                        <Grid
                            style={{ height: "100%" }}
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            container
                        >
                            <Typography
                                className={classes.titleText}
                                variant="h6"
                                component="div"
                            >
                                <TruncateMarkup
                                    lines={2}
                                >
                                    <div>{news.title}</div>
                                </TruncateMarkup>
                            </Typography>
                        </Grid>
                    </CardContent>
                </Card>
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                    <DialogBox
                        open={open}
                        content={this.generateNewsCard(news)}
                        handleClose={this.onHandleClose}
                        fullWidth
                    />
                </div>

            </div>

        );
    }
}

NewsCardCarousel.propTypes = {
    classes: PropTypes.objectOf(PropTypes.object()).isRequired,
    news: PropTypes.objectOf(PropTypes.object()).isRequired,
};

const styles = () => ({
    card: {
        cursor: 'pointer',
        boxSizing: "border-box",
        paddingRight: PADDING_MEDIUM,
        paddingLeft: PADDING_MEDIUM,
        paddingTop: PADDING_SMALL,
        paddingBottom: PADDING_SMALL,
        borderRadius: 0,
        boxShadow: "none",
        minHeight: constants.NEWS_CARD,
        width: "100%",
        backgroundColor: window.backgroundColorNews
    },
    media: {
        objectFit: "contain",
        height: imageMaxHeightNews,
        borderRadius: 5,
        boxShadow: "0px 1px 3px grey",
        background: "white",
    },
    content: {
        ...paddingContentContainer,
    },
    titleText: {
        color: "black",
        width: "100%",
        fontWeight: "600",
        fontSize: 18 * zoom,
        paddingTop: 4,
        textAlign: "center"
    }
});

export default withStyles(styles)(NewsCardCarousel);
