import React from 'react';
import ReactDOM from 'react-dom';
import {
  StylesProvider,
  createGenerateClassName,
} from '@mui/styles';
import News from './News.jsx';
import './intramuros.css';
import commonPaths from '../webpack/paths';

const generateClassName = createGenerateClassName({
  seed: 'news',
});

export function initNewsWidget(cityId, aggloId, cityINSEE, aggloSIREN, excludeActors, intramurosTopicId, intramurosActorId) {
  const rootDiv = document.getElementById('intramuros_news');
  if (rootDiv) {
    // Remove previous instance if exists
    const previousChildNode = document.getElementById(
      'intramuros-news-content',
    );
    if (previousChildNode) {
      rootDiv.removeChild(previousChildNode);
    }

    const node = document.createElement('div');
    node.setAttribute('id', 'intramuros-news-content');

    const child = rootDiv.appendChild(node);

    console.log('Init IntraMuros News widget');
    ReactDOM.render(
      <StylesProvider generateClassName={generateClassName}>
        <News
          cityId={cityId}
          aggloId={aggloId}
          cityINSEE={cityINSEE}
          aggloSIREN={aggloSIREN}
          excludeActors={excludeActors}
          intramurosTopicId={intramurosTopicId}
          intramurosActorId={intramurosActorId}
        />
      </StylesProvider>,
      child,
    );
  } else {
    console.log('No tag with id: intramuros_news');
  }
}

// Launch widget
console.log(`Widget Actualité v${commonPaths?.widgetVersion} chargé.`);
console.log('TARGET_ENV: ' + process.env.TARGET_ENV);
console.log('NODE_ENV: ' + process.env.NODE_ENV);

const {
  intramurosCityIdNews,
  intramurosAggloIdNews,
  intramurosCityINSEENews,
  intramurosAggloSIRENNews,
  excludeActorsNews,
  intramurosTopicIdNews,
  intramurosActorIdNews,
} = window;

console.log('City ID = ' + intramurosCityIdNews);
console.log('Agglo ID = ' + intramurosAggloIdNews);
console.log('Topics ID = ' + intramurosTopicIdNews);
console.log('Actors ID = ' + intramurosActorIdNews);

if (
  intramurosCityIdNews ||
  intramurosAggloIdNews ||
  intramurosCityINSEENews ||
  intramurosAggloSIRENNews ||
  intramurosTopicIdNews ||
  intramurosActorIdNews
) {
  initNewsWidget(
    intramurosCityIdNews,
    intramurosAggloIdNews,
    intramurosCityINSEENews,
    intramurosAggloSIRENNews,
    excludeActorsNews,
    intramurosTopicIdNews,
    intramurosActorIdNews,
  );
}
